import "bootstrap/js/dist/button"
import "bootstrap/js/dist/offcanvas"
import "bootstrap/js/dist/dropdown"
import "bootstrap/js/dist/collapse"
import "../scss/custom.scss"
import "flickity"
import "flickity/css/flickity.css"
// import "masonry-layout"
import * as $ from "jquery"
// import "@fortawesome/fontawesome-svg-core/styles.css"
// import { library, dom } from "@fortawesome/fontawesome-svg-core";
// // import { fad } from "@fortawesome/pro-duotone-svg-icons";
// // import { fal } from "@fortawesome/pro-light-svg-icons";
// import { fat } from "@fortawesome/pro-thin-svg-icons";
// import { fab } from "@fortawesome/free-brands-svg-icons"
// library.add(fad, fal, fat, fab);
// dom.watch();


$(function () {
	$('#GlobalNav').load('../GlobalNav.html');
	$('#GlobalFooter').load('../GlobalFooter.html',scrollBackToTop);
	$(".dropdown").hover(function () {
		$(this).find('ul').slideToggle('medium');
		$(".navbar-toggle").dropdown();
	})
})


//Scroll back to top

const scrollBackToTop = function () {
	"use strict";

	var progressPath = document.querySelector('.progress-wrap path');
	var pathLength = progressPath.getTotalLength();
	progressPath.style.transition = progressPath.style.WebkitTransition = 'none';
	progressPath.style.strokeDasharray = pathLength + ' ' + pathLength;
	progressPath.style.strokeDashoffset = pathLength;
	progressPath.getBoundingClientRect();
	progressPath.style.transition = progressPath.style.WebkitTransition = 'stroke-dashoffset 10ms linear';
	var updateProgress = function () {
		var scroll = $(window).scrollTop();
		var height = $(document).height() - $(window).height();
		var progress = pathLength - (scroll * pathLength / height);
		progressPath.style.strokeDashoffset = progress;
	}
	updateProgress();
	$(window).on('scroll', updateProgress);
	var offset = 50;
	var duration = 550;
	$(window).on('scroll', function () {
		if ($(this).scrollTop() > offset) {
			$('.progress-wrap').addClass('active-progress');
		} else {
			$('.progress-wrap').removeClass('active-progress');
		}
	});
	$('.progress-wrap').on('click', function (event) {
		event.preventDefault();
		$('html, body').animate({ scrollTop: 0 }, duration);
		return false;
	})


};

$(function() {
	$('.material-card > .mc-btn-action').click(function () {
		var card = $(this).parent('.material-card');
		var icon = $(this).children('i');
		icon.addClass('fa-spin-fast');

		if (card.hasClass('mc-active')) {
			card.removeClass('mc-active');

			window.setTimeout(function() {
				icon
					.removeClass('fa-arrow-left')
					.removeClass('fa-spin-fast')
					.addClass('fa-bars');

			}, 800);
		} else {
			card.addClass('mc-active');

			window.setTimeout(function() {
				icon
					.removeClass('fa-bars')
					.removeClass('fa-spin-fast')
					.addClass('fa-arrow-left');

			}, 800);
		}
	});
});